// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-components-blog-grid-blog-post-index-js": () => import("./../src/components/Blog-grid/Blog-post/index.js" /* webpackChunkName: "component---src-components-blog-grid-blog-post-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-entwicklung-js": () => import("./../src/pages/app-entwicklung.js" /* webpackChunkName: "component---src-pages-app-entwicklung-js" */),
  "component---src-pages-cv-js": () => import("./../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-web-entwicklung-js": () => import("./../src/pages/web-entwicklung.js" /* webpackChunkName: "component---src-pages-web-entwicklung-js" */)
}

